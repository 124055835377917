.uuY68G_signature {
  font: var(--font-label-2);
  color: var(--color-neutral-variant-70);
  align-items: center;
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
}

.uuY68G_signature .uuY68G_staticIcon {
  display: block;
}

.uuY68G_signature .uuY68G_highlightIcon, .uuY68G_signature:hover .uuY68G_staticIcon, .uuY68G_signature:active .uuY68G_staticIcon {
  display: none;
}

.uuY68G_signature:hover .uuY68G_highlightIcon, .uuY68G_signature:active .uuY68G_highlightIcon {
  display: block;
}

.uuY68G_signature .uuY68G_text {
  margin-right: 4px;
}

body.mobile .uuY68G_signature {
  color: var(--color-neutral-variant-80);
  font: var(--font-label-3);
}

.uDMMma_viewBox {
  position: absolute;
  inset: 0;
  overflow: auto;
}

.uDMMma_container {
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uDMMma_main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body.mobile .uDMMma_container {
  padding-bottom: env(safe-area-inset-bottom);
}

body.mobile .uDMMma_main {
  background: var(--color-bg-body);
  flex: 1;
  align-self: stretch;
  padding: 16px 20px;
  position: relative;
}

body.mobile .uDMMma_signature {
  margin: 40px 0 8px;
}

body.desktop .uDMMma_container {
  padding: 20px;
}

body.desktop .uDMMma_main {
  width: 540px;
  min-height: 540px;
  background: var(--color-bg-float);
  box-shadow: var(--color-shadow-2);
  border-radius: 16px;
  padding: 24px;
  position: relative;
}

body.desktop .uDMMma_signature {
  padding-bottom: 28px;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 28px));
}

@media only screen and (max-width: 580px) {
  body.desktop .uDMMma_main {
    width: auto;
    align-self: stretch;
  }
}

.Ljn7Ea_button {
  height: 44px;
  border-radius: var(--radius);
  cursor: pointer;
  font: var(--font-label-1);
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  transition: background .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.Ljn7Ea_large {
  width: 100%;
}

.Ljn7Ea_small {
  min-width: 85px;
}

.Ljn7Ea_primary {
  background: var(--color-brand-default);
  color: var(--color-static-white);
  border: none;
}

.Ljn7Ea_primary.Ljn7Ea_disabled, .Ljn7Ea_primary:disabled {
  background: var(--color-bg-state-disabled);
  color: var(--color-type-disable);
}

.Ljn7Ea_primary:active {
  background: var(--color-brand-pressed);
}

.Ljn7Ea_secondary {
  border: 1px solid var(--color-line-border);
  color: var(--color-type-primary);
  background: none;
}

.Ljn7Ea_secondary.Ljn7Ea_disabled, .Ljn7Ea_secondary:disabled {
  border-color: var(--color-type-disable);
  color: var(--color-type-disable);
}

.Ljn7Ea_secondary:active {
  background: var(--color-overlay-neutral-pressed);
}

body.desktop .Ljn7Ea_button {
  font: var(--font-label-2);
}

body.desktop .Ljn7Ea_primary:focus-visible {
  outline: 3px solid var(--color-overlay-brand-focused);
}

body.desktop .Ljn7Ea_primary:not(:disabled):not(:active):hover {
  background: var(--color-brand-hover);
}

body.desktop .Ljn7Ea_secondary:focus-visible {
  outline: 3px solid var(--color-overlay-neutral-focused);
}

body.desktop .Ljn7Ea_secondary:not(:disabled):not(:active):hover {
  background: var(--color-overlay-neutral-hover);
}

.D6WLUq_iconButton {
  border: none;
  border-color: none;
  cursor: pointer;
  background: none;
  border-radius: 6px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

.D6WLUq_iconButton > svg {
  color: var(--color-type-secondary);
}

.D6WLUq_iconButton:active {
  background: var(--color-overlay-neutral-pressed);
}

body.desktop .D6WLUq_iconButton:hover:not(:active) {
  background: var(--color-overlay-neutral-hover);
}

.LxEQZG_overlay {
  background: var(--color-bg-mask);
  position: fixed;
  inset: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.DJy3WW_overlay {
  z-index: 100;
}

.DJy3WW_modal {
  width: 600px;
  border-radius: 16px;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.DJy3WW_modal:focus-visible {
  outline: none;
}

.DJy3WW_container {
  background: var(--color-bg-float-overlay);
  border-radius: 16px;
  padding: 24px;
}

.DJy3WW_container:focus-visible {
  outline: none;
}

.DJy3WW_header {
  font: var(--font-title-1);
  color: var(--color-type-primary);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.DJy3WW_header svg {
  width: 24px;
  height: 24px;
}

.DJy3WW_content {
  font: var(--font-body-2);
  color: var(--color-type-primary);
  margin-bottom: 24px;
}

.DJy3WW_footer {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.DJy3WW_footer > * {
  flex-shrink: 1;
}

.DJy3WW_footer > button:first-child {
  margin-right: 12px;
}

@media only screen and (max-width: 640px) {
  .DJy3WW_modal {
    width: calc(100% - 40px);
  }
}

.XjKGIq_overlay {
  z-index: 100;
}

.XjKGIq_modal {
  max-width: var(--max-width);
  border-radius: var(--radius);
  outline: none;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translate(0, -50%);
}

.XjKGIq_modal:focus-visible {
  outline: none;
}

.XjKGIq_container {
  background: var(--color-bg-body-overlay);
  border-radius: var(--radius);
  padding: 20px;
}

.XjKGIq_container:focus-visible {
  outline: none;
}

.XjKGIq_content {
  text-align: center;
  font: var(--font-body-1);
  color: var(--color-type-primary);
}

.XjKGIq_footer {
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.XjKGIq_footer > * {
  flex: 1;
}

.XjKGIq_footer > button:first-child {
  margin-right: 8px;
}

.XjKGIq_iframeModal {
  top: 40px;
  bottom: 40px;
  transform: none;
}

.XjKGIq_iframeModal .XjKGIq_container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0;
  display: flex;
}

.XjKGIq_iframeModal .XjKGIq_content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.XjKGIq_iframeModal .XjKGIq_footer {
  border-top: 1px solid var(--color-line-divider);
  margin-top: 0;
  padding: 20px;
}

.YrdfMG_navBar {
  width: 100%;
  min-height: 44px;
  color: var(--color-type-primary);
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  display: flex;
  position: relative;
}

.YrdfMG_navBar > svg {
  fill: current-color;
}

.YrdfMG_navBar .YrdfMG_title {
  font: var(--font-title-3);
}

.YrdfMG_navButton {
  font: var(--font-label-2);
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

body.mobile .YrdfMG_navButton > span {
  display: none;
}

body.desktop .YrdfMG_navButton:hover {
  text-decoration: underline;
}

.overlay_f6be6c {
  z-index: 101;
}

.modal_f6be6c {
  z-index: 101;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.container_f6be6c {
  background: var(--color-bg-body);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.modal_f6be6c:focus-visible, .container_f6be6c:focus-visible {
  outline: none;
}

.header_f6be6c {
  padding: 8px 20px;
}

.content_f6be6c {
  width: 100%;
  flex: 1;
}

iframe {
  width: 100%;
  height: 100%;
  background: var(--color-bg-body);
  opacity: 0;
  border: none;
  transition: opacity .2s ease-in-out;
}

iframe.loaded_f6be6c {
  opacity: 1;
}

.loader_f6be6c {
  background: var(--color-brand-default);
}

.ReactModal__Content[id="iframe-modal"] {
  transition: transform .3s ease-in-out;
  transform: translateY(100%);
}

.ReactModal__Content--after-open[id="iframe-modal"] {
  transform: translateY(0);
}

.ReactModal__Content--before-close[id="iframe-modal"] {
  transform: translateY(100%);
}

.toastContainer_ba7e56 {
  pointer-events: none;
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.toast_ba7e56 {
  max-width: 295px;
  font: var(--font-body-1);
  color: var(--color-static-white);
  border-radius: var(--radius);
  background: var(--color-bg-toast);
  text-align: center;
  word-break: break-word;
  pointer-events: auto;
  margin: 0 auto;
  padding: 8px 16px;
}

.toast_ba7e56:focus-visible {
  outline: none;
}

.ReactModal__Content[role="toast"] {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.ReactModal__Content--after-open[role="toast"] {
  opacity: 1;
}

.ReactModal__Content--before-close[role="toast"] {
  opacity: 0;
}

body.desktop .toast_ba7e56 {
  box-shadow: var(--color-shadow-2);
  padding: 12px 16px;
}

body.mUhaAq_light {
  --color-neutral-0: #0000;
  --color-neutral-10: #191c1d;
  --color-neutral-20: #2d3132;
  --color-neutral-50: #747778;
  --color-neutral-80: #c4c7c7;
  --color-neutral-90: #e0e3e3;
  --color-neutral-95: #eff1f1;
  --color-neutral-100: #fff;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #adaab4;
  --color-neutral-variant-80: #c9c5d0;
  --color-neutral-variant-90: #e5e1ec;
  --color-danger-30: #930006;
  --color-danger-40: #ba1b1b;
  --color-danger-50: #dd3730;
  --color-success-60: #32873d;
  --color-success-70: #68be6c;
  --color-success-80: #83da85;
  --color-success-90: #9fe79f;
  --color-brand-30: #4300da;
  --color-brand-40: #5d34f2;
  --color-brand-50: #7958ff;
  --color-alert-70: #eb9918;
  --color-alert-99: #fff5eb;
  --color-type-primary: var(--color-neutral-10);
  --color-type-secondary: var(--color-neutral-50);
  --color-type-disable: #191c1d59;
  --color-type-link: var(--color-brand-default);
  --color-line-divider: #191c1d1f;
  --color-line-border: var(--color-neutral-80);
  --color-line-border-transparent: #191c1d40;
  --color-bg-body-base: var(--color-neutral-95);
  --color-bg-body: var(--color-neutral-100);
  --color-bg-layer-1: var(--color-static-white);
  --color-bg-layer-2: var(--color-neutral-95);
  --color-bg-body-overlay: var(--color-neutral-100);
  --color-bg-float-base: var(--color-neutral-variant-90);
  --color-bg-float: var(--color-neutral-100);
  --color-bg-float-overlay: var(--color-neutral-100);
  --color-bg-mask: #0006;
  --color-bg-toast: var(--color-neutral-20);
  --color-bg-state-unselected: var(--color-neutral-90);
  --color-bg-state-disabled: #191c1d14;
  --color-brand-default: var(--color-brand-40);
  --color-brand-hover: var(--color-brand-50);
  --color-brand-pressed: var(--color-brand-30);
  --color-danger-default: var(--color-danger-40);
  --color-danger-hover: var(--color-danger-50);
  --color-danger-pressed: var(--color-danger-30);
  --color-success-default: var(--color-success-70);
  --color-success-hover: var(--color-success-80);
  --color-success-pressed: var(--color-success-60);
  --color-success-disable: var(--color-success-90);
  --color-overlay-neutral-hover: #191c1d14;
  --color-overlay-neutral-pressed: #191c1d1f;
  --color-overlay-neutral-focused: #191c1d29;
  --color-overlay-danger-hover: #ba1b1b14;
  --color-overlay-danger-pressed: #ba1b1b1f;
  --color-overlay-danger-focused: #ba1b1b29;
  --color-overlay-brand-hover: #5d34f214;
  --color-overlay-brand-pressed: #5d34f21f;
  --color-overlay-brand-focused: #5d34f229;
  --color-surface: var(--color-neutral-99);
}

body.mUhaAq_dark {
  --color-neutral-0: #fff;
  --color-neutral-10: #f7f8f8;
  --color-neutral-20: #eff1f1;
  --color-neutral-50: #a9acac;
  --color-neutral-80: #5c5f60;
  --color-neutral-90: #444748;
  --color-neutral-95: #2d3132;
  --color-neutral-99: #191c1d;
  --color-neutral-100: #000;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #78767f;
  --color-neutral-variant-80: #5f5d67;
  --color-neutral-variant-90: #47464e;
  --color-danger-30: #ffdad4;
  --color-danger-40: #ffb4a9;
  --color-danger-50: #ff897a;
  --color-danger-60: #ff5449;
  --color-danger-70: #dd3730;
  --color-danger-80: #ba1b1b;
  --color-success-60: #4ea254;
  --color-success-70: #32873d;
  --color-success-80: #116d26;
  --color-success-90: #005314;
  --color-brand-30: #e6deff;
  --color-brand-40: #cabeff;
  --color-brand-50: #af9eff;
  --color-brand-70: #7958ff;
  --color-surface: var(--color-neutral-99);
  --color-surface-2: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-surface-3: linear-gradient(0deg, #cabeff1c, #cabeff1c), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-surface-4: linear-gradient(0deg, #cabeff24, #cabeff24), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-alert-70: #a76900;
  --color-alert-99: #2b1700;
  --color-type-primary: var(--color-neutral-10);
  --color-type-secondary: var(--color-neutral-50);
  --color-type-disable: #f7f8f859;
  --color-type-link: var(--color-brand-default);
  --color-line-divider: #f7f8f824;
  --color-line-border: var(--color-neutral-80);
  --color-line-border-transparent: #f7f8f833;
  --color-bg-body-base: var(--color-neutral-100);
  --color-bg-body: var(--color-surface);
  --color-bg-body-overlay: var(--color-surface-2);
  --color-bg-layer-1: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-bg-layer-2: var(--color-surface-4);
  --color-bg-float-base: var(--color-neutral-100);
  --color-bg-float: var(--color-surface-3);
  --color-bg-float-overlay: var(--color-surface-4);
  --color-bg-mask: #0009;
  --color-bg-toast: var(--color-neutral-80);
  --color-bg-state-unselected: var(--color-neutral-90);
  --color-bg-state-disabled: #f7f8f814;
  --color-brand-default: var(--color-brand-70);
  --color-brand-hover: var(--color-brand-50);
  --color-brand-pressed: var(--color-brand-30);
  --color-danger-default: var(--color-danger-70);
  --color-danger-hover: var(--color-danger-60);
  --color-danger-pressed: var(--color-danger-80);
  --color-success-default: var(--color-success-70);
  --color-success-hover: var(--color-success-60);
  --color-success-pressed: var(--color-success-80);
  --color-success-disable: var(--color-success-90);
  --color-overlay-neutral-hover: #f7f8f814;
  --color-overlay-neutral-pressed: #f7f8f81f;
  --color-overlay-neutral-focused: #f7f8f829;
  --color-overlay-danger-hover: #dd373014;
  --color-overlay-danger-pressed: #dd37301f;
  --color-overlay-danger-focused: #dd373029;
  --color-overlay-brand-hover: #cabeff14;
  --color-overlay-brand-pressed: #cabeff1f;
  --color-overlay-brand-focused: #cabeff29;
}

.mUhaAq_preview {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.mUhaAq_preview .mUhaAq_viewBox::-webkit-scrollbar {
  display: none;
}

.mUhaAq_preview main {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

body.mobile {
  --max-width: 360px;
  background: var(--color-bg-body);
}

body.desktop {
  --max-width: 400px;
  background: var(--color-bg-float-base);
}

.qNKCfW_overlay {
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.qNKCfW_loadingIcon {
  color: var(--color-type-primary);
  animation: 1s steps(12, end) infinite qNKCfW_rotating;
}

.qNKCfW_container {
  width: 60px;
  height: 60px;
  border-radius: var(--radius);
  background-color: var(--color-bg-toast);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.qNKCfW_container .qNKCfW_loadingIcon {
  color: var(--color-static-dark-type-primary);
}

@keyframes qNKCfW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.UttbVG_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.OMwqca_container {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.OMwqca_connector {
  margin-bottom: 16px;
}

.OMwqca_connector > img {
  width: 96px;
  height: 96px;
  object-fit: contain;
  object-position: center;
}

.OMwqca_message {
  text-align: center;
  font: var(--font-body-2);
  color: var(--color-type-secondary);
}

.-\37 6m7q_connectorContainer {
  flex: 1;
}

.U5sxCa_viewBox {
  position: absolute;
  inset: 0;
  overflow: auto;
}

.U5sxCa_viewBox .U5sxCa_container {
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.U5sxCa_viewBox .U5sxCa_img {
  height: 40px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 20px;
}

.U5sxCa_viewBox .U5sxCa_loadingWrapper {
  height: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.yIkPZG_container {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.yIkPZG_title {
  text-align: center;
  margin-top: 32px;
}

.yIkPZG_message {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  text-align: center;
}

body.mobile .yIkPZG_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
  margin-bottom: 16px;
}

body.mobile .yIkPZG_backButton {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto 16px;
}

body.desktop .yIkPZG_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
  margin-bottom: 8px;
}

body.desktop .yIkPZG_backButton {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto 48px;
}

.R5EY3G_link {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  display: inline-flex;
}

.R5EY3G_link > svg {
  margin-right: 8px;
}

.R5EY3G_link.R5EY3G_primary {
  font: var(--font-label-2);
  color: var(--color-brand-default);
  text-decoration: none;
}

.R5EY3G_link.R5EY3G_primary:active {
  color: var(--color-brand-hover);
}

.R5EY3G_link.R5EY3G_secondary {
  color: var(--color-type-primary);
  font: inherit;
  text-decoration: none;
}

body.desktop .R5EY3G_link.R5EY3G_primary:hover {
  color: var(--color-brand-hover);
}

body.desktop .R5EY3G_link.R5EY3G_primary:focus-visible {
  outline: 1px solid var(--color-overlay-brand-focused);
}

body.desktop .R5EY3G_link.R5EY3G_secondary:hover, body.desktop .R5EY3G_link.R5EY3G_secondary:active {
  color: var(--color-brand-default);
}

.HCi3vq_notification {
  font: var(--font-body-2);
  color: var(--color-type-primary);
  background: var(--color-neutral-variant-90);
  border: 1px solid var(--color-neutral-variant-80);
  box-shadow: var(--color-shadow-1);
  border-radius: var(--radius);
  z-index: 50;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.HCi3vq_notification .HCi3vq_icon {
  width: 20px;
  height: 20px;
  color: var(--color-neutral-variant-60);
  margin-right: 12px;
}

.HCi3vq_notification .HCi3vq_message {
  flex: 1;
  margin-right: 16px;
}

.HCi3vq_notification .HCi3vq_link {
  max-width: 20%;
}

.TGbnSW_notification {
  font: var(--font-body-2);
  color: var(--color-type-primary);
  background: var(--color-alert-99);
  align-items: center;
  margin: 0 auto 8px;
  padding: 12px 16px;
  display: flex;
}

.Se2C6G_wrapper {
  flex: 1;
  align-self: stretch;
}

.Se2C6G_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.Se2C6G_header {
  margin-bottom: 24px;
}

.Se2C6G_description {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  margin-top: 8px;
}

body.mobile .Se2C6G_container {
  margin-top: 8px;
}

body.mobile .Se2C6G_title {
  font: var(--font-headline-1);
  color: var(--color-type-primary);
}

body.mobile .Se2C6G_notification {
  margin: 0 -20px 24px;
}

body.desktop .Se2C6G_container {
  margin-top: 48px;
}

body.desktop .Se2C6G_header {
  margin-bottom: 16px;
}

body.desktop .Se2C6G_title {
  font: var(--font-headline-2);
  color: var(--color-type-primary);
}

body.desktop .Se2C6G_notification {
  width: 100%;
  max-width: var(--max-width);
  border-radius: var(--radius);
  margin: 24px auto 0;
}

._55yO2a_error {
  font: var(--font-body-2);
  color: var(--color-danger-default);
}

._55yO2a_error ul {
  padding-inline-start: 1rem;
}

.jUeeQa_inputField {
  border: 1px solid var(--color-line-border);
  border-radius: var(--radius);
  height: 44px;
  align-items: stretch;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.jUeeQa_inputField input {
  background: var(--color-bg-body);
  caret-color: var(--color-brand-default);
  font: var(--font-body-1);
  color: var(--color-type-primary);
  flex: 1;
  padding: 0 16px;
  transition: width .3s ease-in;
}

.jUeeQa_inputField input::placeholder {
  color: var(--color-type-secondary);
}

.jUeeQa_inputField .jUeeQa_suffix {
  width: 32px;
  height: 32px;
  z-index: 1;
  display: none;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.jUeeQa_inputField:focus-within {
  border: 1px solid var(--color-brand-default);
}

.jUeeQa_inputField:focus-within input {
  outline: none;
}

.jUeeQa_inputField.jUeeQa_danger {
  border: 1px solid var(--color-danger-default);
}

.jUeeQa_inputField.jUeeQa_danger input {
  caret-color: var(--color-danger-default);
}

.jUeeQa_inputField.jUeeQa_isSuffixFocusVisible:focus-within input {
  padding-right: 40px;
}

.jUeeQa_inputField.jUeeQa_isSuffixFocusVisible:focus-within .jUeeQa_suffix {
  display: flex;
}

.jUeeQa_errorMessage {
  margin-top: 4px;
  margin-left: 2px;
}

body.desktop .jUeeQa_inputField {
  outline: 3px solid #0000;
}

body.desktop .jUeeQa_inputField input {
  font: var(--font-body-2);
  background: var(--color-bg-float);
}

body.desktop .jUeeQa_inputField:focus-within {
  outline-color: var(--color-overlay-brand-focused);
}

body.desktop .jUeeQa_inputField.jUeeQa_danger:focus-within {
  outline-color: var(--color-overlay-danger-focused);
}

._3Ci-sW_prefix {
  overflow: hidden;
}

._3Ci-sW_prefix > div {
  position: absolute;
}

.dropdownOverlay_ec29ff {
  z-index: 40;
  background: none;
  position: fixed;
  inset: 0;
}

.dropdownModal_ec29ff {
  z-index: 50;
  position: absolute;
}

.dropdownModal_ec29ff:focus-visible {
  outline: none;
}

.dropdownContent_ec29ff {
  background: var(--color-bg-float);
  padding: 8px 12px;
}

.searchInputField_ec29ff {
  margin-bottom: 8px;
}

.searchInputField_ec29ff input {
  padding-left: 8px;
}

.searchInputField_ec29ff svg {
  color: var(--color-type-secondary);
  align-self: center;
}

.countryList_ec29ff {
  margin: 0 -12px;
  padding: 0 12px;
  list-style: none;
  overflow: scroll;
}

.countryList_ec29ff li {
  cursor: pointer;
  align-items: center;
  padding: 4px 8px 4px 28px;
  display: flex;
}

.countryList_ec29ff li > svg {
  margin-right: 4px;
}

.countryList_ec29ff li.active_ec29ff {
  color: var(--color-type-link);
  padding-left: 4px;
}

.countryList_ec29ff li.selected_ec29ff {
  background: var(--color-overlay-neutral-hover);
  border-radius: 8px;
}

.notFound_ec29ff {
  color: var(--color-type-secondary);
  text-align: center;
  padding: 4px 8px;
}

body.desktop .dropdownContent_ec29ff {
  border: 1px solid var(--color-line-divider);
  box-shadow: var(--color-shadow-2);
  border-radius: 8px;
}

body.desktop .searchInputField_ec29ff > div {
  height: auto;
  padding: 6px 12px;
}

body.desktop .countryList_ec29ff {
  max-height: 400px;
}

body.mobile .dropdownOverlay_ec29ff {
  z-index: 200;
}

body.mobile .dropdownModal_ec29ff {
  inset: 0;
}

body.mobile .dropdownContent_ec29ff {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

body.mobile .countryList_ec29ff {
  font: var(--font-body-1);
  flex: 1;
  overflow: auto;
}

body.mobile .searchInputField_ec29ff:not(:first-child) {
  margin-top: 8px;
}

body.mobile .searchInputField_ec29ff > div {
  padding-left: 16px;
}

body.mobile .ReactModal__Content[id="country-code-dropdown"] {
  transition: transform .3s ease-in-out;
  transform: translateX(100%);
}

body.mobile .ReactModal__Content--after-open[id="country-code-dropdown"] {
  transform: translateX(0);
}

body.mobile .ReactModal__Content--before-close[id="country-code-dropdown"] {
  transform: translateX(100%);
}

.Q8dYkq_countryCodeSelector {
  font: var(--font-label-1);
  color: var(--color-type-primary);
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  height: 100%;
  white-space: nowrap;
  background: none;
  border: 1px solid #0000;
  align-items: center;
  padding-left: 16px;
  padding-right: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Q8dYkq_countryCodeSelector:focus-visible {
  border: 1px solid var(--color-brand-default);
}

.Q8dYkq_countryCodeSelector > svg {
  color: var(--color-type-secondary);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 4px;
}

body.desktop .Q8dYkq_countryCodeSelector {
  font: var(--font-body-2);
}

body.desktop .Q8dYkq_countryCodeSelector > svg {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.uRK25q_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uRK25q_form > * {
  width: 100%;
}

.uRK25q_form .uRK25q_inputField, .uRK25q_form .uRK25q_formErrors {
  margin-bottom: 16px;
}

.uRK25q_form .uRK25q_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.KdxC9G_description {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  margin-top: 24px;
}

.i0vptW_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.i0vptW_form > * {
  width: 100%;
}

.i0vptW_form .i0vptW_inputField, .i0vptW_form .i0vptW_formErrors, .i0vptW_form .i0vptW_passwordSwitch {
  margin-bottom: 16px;
}

.i0vptW_form .i0vptW_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.i0vptW_passwordSwitch {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.i0vptW_passwordSwitch .i0vptW_checkbox {
  fill: var(--color-type-secondary);
  cursor: pointer;
  margin-right: 8px;
}

.mYxYSW_checkbox {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.mYxYSW_checkbox .mYxYSW_icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.mYxYSW_checkbox .mYxYSW_icon.mYxYSW_small {
  width: 16px;
  height: 16px;
}

.mYxYSW_checkbox .mYxYSW_icon > * {
  display: none;
}

.mYxYSW_checkbox .mYxYSW_icon > :nth-child(1) {
  color: var(--color-type-secondary);
}

.mYxYSW_checkbox .mYxYSW_icon > :nth-child(2) {
  color: var(--color-brand-default);
}

.mYxYSW_checkbox input {
  width: 16px;
  height: 16px;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.mYxYSW_checkbox input:not(:checked):not(:disabled) ~ .mYxYSW_icon > :nth-child(1), .mYxYSW_checkbox input:checked:not(:disabled) ~ .mYxYSW_icon > :nth-child(2), .mYxYSW_checkbox input:checked:not(:disabled) ~ .mYxYSW_icon > :nth-child(3) {
  display: block;
}

body.mobile .mYxYSW_checkbox .mYxYSW_icon, body.mobile .mYxYSW_checkbox .mYxYSW_icon.mYxYSW_small {
  width: 18px;
  height: 18px;
}

.jUsIwq_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.jUsIwq_form > * {
  width: 100%;
}

.jUsIwq_form .jUsIwq_inputField, .jUsIwq_form .jUsIwq_formErrors {
  margin-bottom: 16px;
}

.jUsIwq_form .jUsIwq_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.ctiCua_container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ctiCua_logo {
  height: 40px;
  width: auto;
  object-fit: contain;
  object-position: center;
}

.ctiCua_headline {
  text-align: center;
  color: var(--color-type-primary);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
  display: -webkit-box;
  overflow: hidden;
}

body.mobile .ctiCua_container {
  height: 15vh;
  min-height: 92px;
  max-height: 148px;
}

body.mobile .ctiCua_logo:not(:last-child) {
  margin-bottom: 8px;
}

body.mobile .ctiCua_headline {
  font: var(--font-title-1);
}

body.desktop .ctiCua_logo:not(:last-child) {
  margin-bottom: 12px;
}

body.desktop .ctiCua_headline {
  font: var(--font-title-2);
}

._0GmsMq_appNotification {
  max-width: 520px;
  position: absolute;
}

body.mobile ._0GmsMq_appNotification {
  margin: 0 auto;
  top: 24px;
  left: 20px;
  right: 20px;
}

body.desktop ._0GmsMq_appNotification {
  width: max-content;
  max-width: calc(100% - 40px);
  left: 50%;
  transform: translate(-50%);
}

.pdwYva_wrapper {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  flex: 1;
  justify-content: normal;
  align-self: stretch;
  align-items: normal;
  margin: 0 auto;
  display: flex;
}

.pdwYva_wrapper > :last-child {
  margin-bottom: 0;
}

body.mobile .pdwYva_header {
  margin-top: 12px;
  padding-bottom: 28px;
}

body.desktop .pdwYva_wrapper {
  padding: 24px 0;
}

body.desktop .pdwYva_header {
  margin-bottom: 24px;
}

body.desktop .pdwYva_placeholderTop {
  flex: 3;
}

body.desktop .pdwYva_placeholderBottom {
  flex: 5;
}

.xfK4vq_divider {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  align-items: center;
  display: flex;
}

.xfK4vq_divider .xfK4vq_line {
  height: 1px;
  background: var(--color-line-divider);
  flex: 1;
}

.xfK4vq_divider .xfK4vq_line:first-child {
  margin-right: 16px;
}

.xfK4vq_divider .xfK4vq_line:last-child {
  margin-left: 16px;
}

.yRYpXa_socialButton {
  justify-content: normal;
  padding: 0 16px;
}

.yRYpXa_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}

.yRYpXa_name {
  height: 100%;
  flex: 1;
  align-items: center;
  margin-left: -24px;
  display: flex;
  overflow: hidden;
}

.yRYpXa_name span {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
}

.yRYpXa_name .yRYpXa_placeHolder {
  flex: 1;
}

.yRYpXa_name .yRYpXa_placeHolder:first-child {
  min-width: 40px;
}

body.desktop .yRYpXa_name span {
  line-height: 16px;
}

._1lnZ2a_socialLinkList {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._1lnZ2a_socialLinkButton {
  margin-bottom: 16px;
}

._1lnZ2a_socialLinkButton:last-child {
  margin-bottom: 0;
}

._1lnZ2a_expandIcon > svg {
  width: 20px;
  height: 20px;
}

._1lnZ2a_expandIcon._1lnZ2a_expanded {
  transform: rotate(180deg);
}

._2WTKYW_link {
  color: var(--color-type-primary);
  display: inline;
}

._2WTKYW_divider {
  width: 1px;
  margin: 0 12px;
  position: relative;
}

._2WTKYW_divider:after {
  content: "";
  width: 1px;
  height: 10px;
  background-color: var(--color-line-divider);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VJP9aa_terms {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.VJP9aa_checkbox {
  fill: var(--color-type-secondary);
  cursor: pointer;
  margin-right: 8px;
}

.VJP9aa_content {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  font: var(--font-body-3);
}

.VJP9aa_errorMessage {
  margin-top: 8px;
}

body.mobile .VJP9aa_terms {
  padding: 4px 0;
}

._0W0Rda_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._0W0Rda_form > * {
  width: 100%;
}

._0W0Rda_form ._0W0Rda_inputField, ._0W0Rda_form ._0W0Rda_terms, ._0W0Rda_form ._0W0Rda_formErrors {
  margin-bottom: 16px;
}

._0W0Rda_form ._0W0Rda_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.l0GcaG_main, .l0GcaG_divider, .l0GcaG_otherMethods, .l0GcaG_terms {
  margin-bottom: 16px;
}

.l0GcaG_createAccount {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.l0GcaG_placeHolder {
  flex: 1;
}

body.desktop .l0GcaG_placeHolder {
  flex: 0;
}

.idrqUa_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.idrqUa_form > * {
  width: 100%;
}

.idrqUa_form .idrqUa_inputField, .idrqUa_form .idrqUa_formErrors {
  margin-bottom: 16px;
}

.idrqUa_form .idrqUa_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.H0bY_G_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.H0bY_G_form > * {
  width: 100%;
}

.H0bY_G_form .H0bY_G_inputField, .H0bY_G_form .H0bY_G_link, .H0bY_G_form .H0bY_G_terms, .H0bY_G_form .H0bY_G_formErrors {
  margin-bottom: 16px;
}

.H0bY_G_form .H0bY_G_link {
  width: auto;
  align-self: start;
  margin-top: -4px;
}

.H0bY_G_form .H0bY_G_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.desktop .H0bY_G_form .H0bY_G_link {
  margin-top: -8px;
}

.ZAqXAW_main, .ZAqXAW_otherMethods, .ZAqXAW_divider {
  margin-bottom: 16px;
}

.ZAqXAW_terms {
  text-align: center;
  font: var(--font-body-3);
  margin-top: 16px;
}

.ZAqXAW_createAccount {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.ZAqXAW_placeHolder {
  flex: 1;
}

body.desktop .ZAqXAW_placeHolder {
  flex: 0;
}

.jWjpkG_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.jWjpkG_form > * {
  width: 100%;
}

.jWjpkG_form .jWjpkG_inputField, .jWjpkG_form .jWjpkG_link, .jWjpkG_form .jWjpkG_formErrors {
  margin-bottom: 16px;
}

.jWjpkG_form .jWjpkG_switch {
  margin-top: 24px;
}

.jWjpkG_form .jWjpkG_link, .jWjpkG_form .jWjpkG_switch {
  width: auto;
  align-self: start;
}

.jWjpkG_form .jWjpkG_link {
  margin-top: -4px;
}

.jWjpkG_form .jWjpkG_formErrors {
  margin-top: -12px;
  margin-left: 2px;
}

.desktop .jWjpkG_form .jWjpkG_link {
  margin-top: -8px;
}

.HgNuBq_connectorContainer {
  flex: 1;
}

.jPOtVa_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.jPOtVa_container > * {
  width: 100%;
}

.jPOtVa_desc {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
  text-align: left;
}

.jPOtVa_divider {
  margin: 20px 0;
}

body.mobile .jPOtVa_desc {
  margin-bottom: 8px;
}

body.desktop .jPOtVa_desc {
  margin-bottom: 16px;
}

.Ndr59a_passcode {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Ndr59a_passcode input {
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-line-border);
  text-align: center;
  font: var(--font-label-1);
  color: var(--color-type-primary);
  caret-color: var(--color-brand-default);
  background: none;
  border-radius: 8px;
}

.Ndr59a_passcode input:focus {
  border: 1px solid var(--color-brand-default);
  outline: none;
}

.Ndr59a_passcode input::placeholder {
  color: var(--color-type-secondary);
}

.Ndr59a_passcode + .Ndr59a_errorMessage {
  margin-top: 4px;
  margin-left: 2px;
}

body.desktop .Ndr59a_passcode input {
  font: var(--font-body-2);
  outline: 3px solid #0000;
}

body.desktop .Ndr59a_passcode input:focus {
  border: 1px solid var(--color-brand-default);
  outline-color: var(--color-overlay-brand-focused);
}

._8gzYyG_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._8gzYyG_form > * {
  width: 100%;
}

._8gzYyG_form ._8gzYyG_inputField {
  margin-bottom: 32px;
}

._8gzYyG_form ._8gzYyG_inputField._8gzYyG_withError {
  margin-bottom: 8px;
}

._8gzYyG_form ._8gzYyG_message {
  font: var(--font-body-2);
  color: var(--color-type-secondary);
}

._8gzYyG_form ._8gzYyG_message, ._8gzYyG_form ._8gzYyG_link, ._8gzYyG_form ._8gzYyG_switch {
  width: auto;
  align-self: start;
}

._8gzYyG_form ._8gzYyG_switch {
  margin-top: 24px;
}

body {
  --color-static-white: #fff;
  --color-static-black: #000;
  --color-static-dark-type-primary: #f7f8f8;
  --color-shadow-1: 0 4px 8px #00000014;
  --color-shadow-2: 0 4px 8px #00000005;
  --font-headline-1: 600 28px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-headline-2: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-title-1: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-title-2: 600 18px / 26px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-title-3: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-label-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-label-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-label-3: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-body-1: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-body-2: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-body-3: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --font-subhead-cap: 700 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Arial, sans-serif;
  --radius: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  word-break: break-word;
  color: var(--color-type-primary);
  font: var(--font-body-2);
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-underline-offset: 2px;
}

input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

:focus-visible {
  outline: solid 1px var(--color-brand-default);
}

/*# sourceMappingURL=index.4d32de7a.css.map */
